<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="模板名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="设备型号" prop="deviceModelIds">
                <a-select v-model="queryParam.deviceModelIds" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'启用',key:0},{name:'禁用',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd(0)" v-hasPermi="['tl:template:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tl:template:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tl:template:remove']">
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :tempType="0" @ok="getList" />
      <!-- 模版天 -->
      <Days ref="days" @ok="getList" />
      <!-- 导入 -->
      <dr ref="dr" @ok="getList" />
      <!-- 模版字体 -->
      <Font ref="font" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="pic" slot-scope="text, record">
          <viewer :images="[record.picPathStr]">
            <img style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="record.picPathStr">
          </viewer>
        </span>
        <span slot="type" slot-scope="text, record">
          <span v-if="record.type == 0">系统模板</span>
          <span v-if="record.type == 1">DIY模板</span>
        </span>
        <span slot="deviceModelName" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">{{ record.deviceModelName ? record.deviceModelName : '暂无' }}</template>
            <a>查看</a>
          </a-tooltip>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="confirmHandleStatus(record)"
            @cancel="cancelHandleStatus(record)">
            <span slot="title">确认<b>{{ record.status === 1 ? '启用' : '禁用' }}</b>{{ record.nickName }}的模版吗?</span>
            <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 0 " />
          </a-popconfirm>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.dr.handleAdd(record.id)" v-hasPermi="['tl:templateday:edit']">导入</a>
          <a-divider type="vertical" v-hasPermi="['tl:templateday:edit']"/>
          <a @click="$refs.days.getList(record.id)">模版天</a>
          <a-divider type="vertical" v-hasPermi="['tl:template:edit']" />
          <!-- <a @click="$refs.font.getList(record.id)">模版字体</a>
          <a-divider type="vertical" v-hasPermi="['tl:template:edit']" /> -->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tl:template:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tl:template:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tl:template:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDeviceType } from '@/api/valve/deviceType'
import { pageTemplate, delTemplate, statusTemplate } from '@/api/valve/template'
import CreateForm from './modules/CreateForm'
import Days from './modules/days'
import Font from './modules/font'
import dr from './modules/dr'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Template',
  components: {
    CreateForm, Days, Font, dr
  },
  mixins: [tableMixin],
  data () {
    return {
      projectList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      deviceTypeList: [],
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        deviceModelIds: undefined,
        name: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '模板名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备型号',
          ellipsis: true,
          scopedSlots: { customRender: 'deviceModelName' },
          align: 'center'
        },
        {
          title: '年份',
          dataIndex: 'year',
          width: 80,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '天数',
          dataIndex: 'days',
          width: 80,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '封面图',
          ellipsis: true,
          scopedSlots: { customRender: 'pic' },
          align: 'center'
        },
        {
          title: '模板类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 100,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 80,
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 230,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    pageDeviceType({ type: 0, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
      this.deviceTypeList = response.data.records
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
     pageTemplate({ type: 0, ...this.queryParam }).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        deviceModelIds: undefined,
        name: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /* 模版状态修改 status 0开启 1禁用 */
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 1 ? '启用' : '禁用'
      const status = row.status === 1 ? 0 : 1
      statusTemplate({ id: row.id, status }).then((res) => {
        if (res.success) {
          row.status = status
          this.$message.success(text + '成功', 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delTemplate(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/Template/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
