<template>
  <a-drawer width="1200" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>模版字体信息</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <!-- <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="编码" prop="code">
                  <a-input v-model="queryParam.code" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="天数" prop="day">
                  <a-input v-model="queryParam.day" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div> -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false">
        <span slot="type" slot-scope="text, record">
          <span v-if="record.type == 1">日</span>
          <span v-if="record.type == 2">年</span>
          <span v-if="record.type == 3">月（英文）</span>
          <span v-if="record.type == 4">月（中文）</span>
          <span v-if="record.type == 5">星期</span>
          <span v-if="record.type == 6">农历</span>
          <span v-if="record.type == 7">消息内容</span>
        </span>
        <span slot="bold" slot-scope="text, record">
          <span v-if="record.bold == 0">正常</span>
          <span v-if="record.bold == 1">加粗</span>
          <span v-if="record.bold == 2">缩小</span>
        </span>
        <span slot="point" slot-scope="text, record">
          <span>{{ 'X:' + record.pointX + ',Y:' + record.pointY }}</span>
        </span>
        <span slot="colorRgb" slot-scope="text, record">
          <a-tag :color="`rgb(${record.colorRgb})`">{{ record.colorRgb }}</a-tag>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['tl:templatefont:edit']">
            <a-icon type="edit" />修改
          </a>
        </span>
      </a-table>

      <a-drawer width="500px" :label-col="4" :wrapper-col="14" :visible="open1" @close="onClose1">
        <a-divider orientation="left">
          <b>编辑天信息</b>
        </a-divider>
        <a-form-model ref="form" :model="form" :rules="rules">
          <a-form-model-item label="类型" prop="code">
            <a-select v-model="form.type" placeholder="请选择" allow-clear disabled>
              <a-select-option v-for="(item,key) in [{name:'日',key:1},{name:'年',key:2},{name:'月（英文）',key:3},{name:'月（中文）',key:4},{name:'星期',key:5},{name:'农历',key:6},{name:'消息内容',key:7}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="粗体" prop="bold">
            <a-select v-model="form.bold" placeholder="请选择" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'正常',key:0},{name:'加粗',key:1},{name:'缩小',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="颜色" prop="colorRgb">
            <a-input v-model="form.colorRgb" placeholder="请输入（0,0,0）"/>
          </a-form-model-item>
          <a-form-model-item label="起始点坐标X" prop="pointX">
            <a-input v-model="form.pointX" placeholder="请输入" type="number"/>
          </a-form-model-item>
          <a-form-model-item label="起始点坐标Y" prop="pointY">
            <a-input v-model="form.pointY" placeholder="请输入" type="number"/>
          </a-form-model-item>
          <a-form-model-item label="大小" prop="size">
            <a-input v-model="form.size" placeholder="请输入" type="number"/>
          </a-form-model-item>
          <a-form-model-item label="字体" prop="typeface">
            <a-input v-model="form.typeface" placeholder="请输入"/>
          </a-form-model-item>
          <div class="bottom-control">
            <a-space>
              <a-button type="primary" :loading="submitLoading" @click="submitForm">
                保存
              </a-button>
              <a-button type="dashed" @click="cancel">
                取消
              </a-button>
            </a-space>
          </div>
        </a-form-model>
        <up-img-modal ref="upImg" :width="335" :height="342" @ok="setImg"/>
      </a-drawer>
    </a-card>
  </a-drawer>
</template>

<script>
import { pageTemplateFont, getTemplateFont, updateTemplateFont } from '@/api/valve/template'
import upImgModal from '@/components/upImgModal'

export default {
  name: 'User',
  props: {
  },
  components: {
    upImgModal
  },
  data() {
    return {
      did: null,
      open: false,
      submitLoading: false,

      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        templateId: 0,
        code: null,
        day: null,
        pageNum: 1,
        pageSize: 10
      },
      // 非多个禁用
      multiple: true,
      selectedRowKeys: [],
      selectedRows: [],
      list: [],
      columns: [
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '模板名称',
        //   dataIndex: 'templateName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '起始点坐标',
          scopedSlots: { customRender: 'point' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '字体',
          dataIndex: 'typeface',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '大小',
          dataIndex: 'size',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '粗体',
          dataIndex: 'bold',
          scopedSlots: { customRender: 'bold' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '颜色',
          dataIndex: 'colorRgb',
          scopedSlots: { customRender: 'colorRgb' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '添加时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center',
        //   width: 150
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      open1: false,
      // 表单参数
      form: {
        id: 0,
        bold: 0,
        colorRgb: '',
        pointX: 0,
        pointY: 0,
        size: 0,
        typeface: ''
      },
      rules: {
        picPath: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    getList(id) {
      this.queryParam.templateId = id || this.queryParam.templateId
      this.open = true
      this.loading = true
      pageTemplateFont(this.queryParam).then(res => {
        const list = []
        res.data.records.map(item => {
          if (item.type !== 7) {
            list.push(item)
          }
        })
        this.list = list
        this.total = res.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam.code = null
      this.queryParam.day = null
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onClose() {
      this.open = false
    },
    onClose1() {
      this.open1 = false
    },
    // 表单重置
    reset() {
      this.form = {
        id: 0,
        bold: 0,
        colorRgb: '',
        pointX: 0,
        pointY: 0,
        size: 0,
        typeface: ''
      }
    },
    // 取消按钮
    cancel() {
      this.open1 = false
      this.reset()
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      this.formType = 2
      const id = row.id
      getTemplateFont({ id }).then(response => {
        this.form = response.data
        this.open1 = true
        this.formTitle = '修改'
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.picPath
        }]
      })
    },
    setImg (url) {
      console.log(url)
      this.form.picPath = url
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateTemplateFont(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open1 = false
              this.getList()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
